














































































































































































































































import {Component, Watch, Vue} from "vue-property-decorator";
import {Task} from "../../models/interfaces/Task";
import {TaskStore, TaskStoreModule} from "../../stores/TaskStore";
import GanttChart from "../Atoms/GanttChart.vue";
import NotifySnackBar from "../Atoms/NotifySnackBar.vue";
import {TaskModel} from "../../models/TaskModel";
import WorkLoadCalculator from "../../models/WorkLoadCalculator";
import TableSelectableItem from "../Atoms/TableSelectableItem.vue";
import {MasterInfoStoreModule, MasterInfoStore} from "../../stores/MasterInfoStore";
import TableTextFieldDialog from "@/components/Atoms/TableTextFieldDialog.vue";
import TableTextareaDialog from "@/components/Atoms/TableTextareaDialog.vue";
import TableDatePickerDialog from "@/components/Atoms/TableDatePickerDialog.vue";
import NameById from "@/components/Atoms/NameById.vue";
import {IdAndName} from "../../models/interfaces/IdAndName";
import {DefaultTaskSearchParam} from "../../Config";
import {SheetStore, SheetStoreModule} from "../../stores/SheetStore";
import TaskSearchForm from "@/components/Organisms/TaskSearchForm.vue";
import {TaskSearchParamModel} from "../../models/TaskSearchParamModel";
import * as CONFIG from "@/Config";
import moment from "moment";
import ConfirmDialog from "@/components/Atoms/ConfirmDialog.vue";
import TaskThreadView from "../Templates/TaskThreadView.vue";
import {VDataTableOptions} from "../../models/interfaces/VDataTableOptions";

@Component({
    components: {
        GanttChart,
        NotifySnackBar,
        TableSelectableItem,
        TableTextFieldDialog,
        TableTextareaDialog,
        NameById,
        TaskSearchForm,
        TableDatePickerDialog,
        TaskThreadView,
        ConfirmDialog
    }
})
export default class GanttChartPage extends Vue {
    // #region private fields
    private resizing = false;
    private tableWidth = 600;
    private tableHeight = 0;
    private isScrollSelf = false;
    private tableWrapper?: HTMLElement = undefined;
    private date = "";
    private workLoadCalculator = new WorkLoadCalculator();
    private isShowSearchPanel = false;
    private openedThreadId = 0;
    private tasks: TaskModel[] = [];
    private ganttTasks: TaskModel[] = [];
    private sortBy: string[] = [];
    private sortDesc: boolean[] = [];
    private searchParams: TaskSearchParamModel = CONFIG.DefaultGanttChartSearchParam();
    private headers = [
        {text: "", sortable: false, width: 32},
        {text: "No", value: "id", sortable: false, width: 32},
        {text: "システム", value: "systemId", width: 100},
        {text: "ステータス", value: "statusId", width: 100},
        {text: "大項目", value: "category", width: 100},
        {text: "詳細", value: "detail", sortable: false, width: 160},
        {text: "工数", value: "cost", width: 80},
        {text: "調整", value: "optionHolidayCount", sortable: false, width: 30},
        {text: "開始日", value: "developStartDate", width: 90},
        {text: "終了日", value: "developEndDate", width: 90},
        {text: "公開日", value: "releaseDate", width: 90},
        {text: "担当者名", value: "personId", width: 90}
    ];
    private isLoading = false;
    // #endregion

    private get taskStore(): TaskStore {
        return TaskStoreModule;
    }

    private get sheetStore(): SheetStore {
        return SheetStoreModule;
    }

    private get masterInfoStore(): MasterInfoStore {
        return MasterInfoStoreModule;
    }

    private async created(): Promise<void> {
        const json = localStorage.getItem("sortBy_gantt");
        if (json) {
            this.sortBy = JSON.parse(json);
        }

        const sortDescJson = localStorage.getItem("sortDesc_gantt");
        if (sortDescJson) {
            this.sortDesc = JSON.parse(sortDescJson);
        }

        this.initTableInfo();
        this.isLoading = true;
        this.loadSearchParam();
        await this.taskStore.fetchTasks(this.searchParams);
        this.applyFilteredTasks();
        this.isLoading = false;
    }

    private loadSearchParam(): void {
        const json = localStorage.getItem("ganttChart_searchParam");
        if (json) {
            Object.assign(this.searchParams, JSON.parse(json));
        }
        else {
            this.searchParams = CONFIG.DefaultGanttChartSearchParam();
        }

        this.searchParams.sheetId = this.sheetStore.currentSheetId;
    }

    private saveSearchParam(): void {
        localStorage.setItem("ganttChart_searchParam", JSON.stringify(this.searchParams));
    }

    private onUpdateOptions(options: Partial<VDataTableOptions>): void {
        this.sortBy = [];
        if (options.sortBy) {
            this.sortBy = options.sortBy;
            localStorage.setItem("sortBy_gantt", JSON.stringify(options.sortBy));
        }

        this.sortDesc = [];
        if (options.sortDesc) {
            this.sortDesc = options.sortDesc;
            localStorage.setItem("sortDesc_gantt", JSON.stringify(options.sortDesc));
        }
    }

    private applySortedGanttTasks() {
        const table = this.$refs.table as any;
        if (table && table.internalCurrentItems) {
            this.$nextTick(() => {
                this.ganttTasks = [...table.internalCurrentItems] as TaskModel[];
                //  this.$forceUpdate();
            });
        }
    }

    private mounted(): void {
        const tableWrapper = document.querySelector(".v-data-table__wrapper") as HTMLElement;
        if (tableWrapper) {
            // データテーブルでスクロールイベントがあったとき
            // ガントチャートビューに伝達する
            this.tableWrapper = tableWrapper;
            tableWrapper.style.overflowX = "scroll";
            tableWrapper.addEventListener("scroll", e => {
                if (this.isScrollSelf) {
                    this.isScrollSelf = false;
                    return;
                }

                const gantt = this.$refs.gantt as GanttChart;
                if (gantt) {
                    gantt.scrollTaskY(e.srcElement as Element);
                }
            });
        }
    }

    /**
     * タスクをフィルタリングし適用します.
     */
    // @Watch("isShowYarou")
    private applyFilteredTasks() {
        // const tasks: TaskModel[] = [];
        // // const isShowYarou = this.isShowYarou;
        // for (const item of this.taskStore.tasks)
        // {
        //     const status = item.statusId;
        //     if (status === 5 || status === 3 || status === 9 || status === 97 || status === 98 || status === 99 || status === 999 || (!isShowYarou && status === 6))
        //     {
        //         continue;
        //     }
        //     tasks.push(item);
        // }
        // this.tasks = tasks;
        this.tasks = this.taskStore.tasks;
        this.applySortedGanttTasks();
    }

    /**
     * @summary ガントチャート表示エリアとタスク表示エリアの大きさを調整します.
     */
    private mouseMove(e: MouseEvent): void {
        if (this.resizing) {
            let rate = this.tableWidth;
            rate += e.movementX;
            if (rate < 30) {
                rate = 30;
            }
            this.tableWidth = rate;
        }
    }

    /**
     * @summary 表のサイズの変更に関する初期化処理.
     */
    private initTableInfo(): void {
        const storagedWidth = localStorage.getItem("tableWidth");
        if (storagedWidth) {
            const tableWidth = parseInt(storagedWidth);
            if (storagedWidth) {
                this.tableWidth = tableWidth;
            }
        }

        window.addEventListener("mousemove", (e) => {
            this.mouseMove(e);
        });

        window.addEventListener("mouseup", (e) => {
            this.resizing = false;
            localStorage.setItem("tableWidth", this.tableWidth.toString());
        });

        window.addEventListener("resize", (e) => {
            this.tableHeight = window.innerHeight - 144;
        });

        this.tableHeight = window.innerHeight - 144;
    }

    /**
     * @summary ガントチャートでスクロールイベントが発火したとき
     */
    private onScrollGanttChartView(e: UIEvent): void {
        this.isScrollSelf = true;
        if (this.tableWrapper && e.srcElement) {
            this.tableWrapper.scrollTop = (e.srcElement as Element).scrollTop;
        }
    }

    /**
     * @summary 変更通知バーを表示します
     * @param text 表示するテキスト
     * @param color 色
     * @param timeout 表示する時間(ms)
     */
    private openNotifySnackBar(text: string, color: string, timeout = 6000) {
        const notifySnackBar = this.$refs.notifySnackBar as NotifySnackBar;
        if (!notifySnackBar) {
            return;
        }

        notifySnackBar.show(text, color, timeout);
    }

    /**
     * @summary タスクを保存します.
     * @param 保存するタスク
     */
    public async saveTask(task: TaskModel): Promise<void> {
        this.isLoading = true;
        const isSuccess = await this.taskStore.saveTask(task);
        this.isLoading = false;
        if (isSuccess) {
            this.openNotifySnackBar("更新しました", "success");
        }
        else {
            this.openNotifySnackBar("更新に失敗しました", "error");
        }
    }

    /**
     * @summary 工数の保存
     */
    private async saveCost(task: TaskModel, cost: number): Promise<void> {
        if (!task.developStartDate) {
            return;
        }

        // 工数から公開開始日を逆算
        // バック側に休日の処理が入るまでの対応
        const date: Date = this.workLoadCalculator.getCompleteDate(new Date(task.developStartDate), cost + task.optionHolidayCount);
        let format = "YYYY-MM-DD";
        format = format.replace(/YYYY/g, date.getFullYear().toString());
        format = format.replace(/MM/g, ("0" + (date.getMonth() + 1)).slice(-2));
        format = format.replace(/DD/g, ("0" + date.getDate()).slice(-2));

        task.cost = cost;
        task.developEndDate = format;
        task.startDate = new Date(task.developStartDate);
        task.completeDate = new Date(task.developEndDate);

        await this.saveTask(task);
    }

    /**
     * @summary 開発終了日から工数を算出して保存します.
     * @param item 保存するタスク
     */
    private async saveDevelopEndDate(item: TaskModel): Promise<void> {
        item.cost = this.workLoadCalculator.getWorkLoad(
            new Date(item.developStartDate),
            new Date(item.developEndDate)
        );
        item.completeDate = new Date(item.developEndDate);
        await this.saveTask(item);
    }

    /**
     * @summary 開発開始日の保存
     */
    private async saveDevelopStartDate(item: TaskModel): Promise<void> {
        // 工数から公開開始日を逆算
        // バック側に休日の処理が入るまでの対応
        const date: Date = this.workLoadCalculator.getCompleteDate(new Date(item.developStartDate), Number(item.cost));
        let format = "YYYY-MM-DD";
        format = format.replace(/YYYY/g, date.getFullYear().toString());
        format = format.replace(/MM/g, ("0" + (date.getMonth() + 1)).slice(-2));
        format = format.replace(/DD/g, ("0" + date.getDate()).slice(-2));

        item.developEndDate = format;
        item.startDate = new Date(item.developStartDate);
        item.completeDate = new Date(item.developEndDate);

        await this.saveTask(item);
    }

    public initDate(date: string): void {
        this.date = date;
    }

    private async openDevelopEndDateEdit(event: MouseEvent, task: TaskModel): Promise<void> {
        const inputDevelopEndDate = await this.openDatePickerDialog(event, task.developEndDate).catch(err => void (err));
        if (!inputDevelopEndDate) {
            return;
        }

        if (!task.developEndDate || !task.releaseDate) {
            task.developEndDate = inputDevelopEndDate;
            this.saveDevelopEndDate(task);
            return;
        }

        const developEndDate = moment(inputDevelopEndDate, "YYYY-MM-DD");
        const releaseDate = moment(task.releaseDate, "YYYY-MM-DD");

        if (developEndDate.isBefore(releaseDate)) {
            task.developEndDate = inputDevelopEndDate;
            this.saveDevelopEndDate(task);
        }
        else {
            const dialog = this.$refs.confirmDialog as ConfirmDialog;
            if (!dialog) {
                return;
            }

            const confirmResult = await dialog.showAsync(
                "開発終了日変更確認",
                "開発終了日は公開日より前でなければいけません。\n選択された開発終了日：" + inputDevelopEndDate.split("-").join("/") + "\n公開日：" + task.releaseDate.split("-").join("/") + "\nどうしますか？",
                "わかりました。再度選択します",
                "開発終了日の変更をキャンセルします"
            );

            if (!confirmResult) {
                return;
            }

            // ダイアログを再度表示
            this.openDevelopEndDateEdit(event, task);
        }
    }

    private async openReleaseDateEdit(event: MouseEvent, task: TaskModel): Promise<void> {
        const inputReleaseDate = await this.openDatePickerDialog(event, task.releaseDate).catch(err => void (err));
        if (!inputReleaseDate) {
            return;
        }

        if (!task.developEndDate) {
            task.releaseDate = inputReleaseDate;
            this.saveTask(task);
            return;
        }

        const releaseDate = moment(inputReleaseDate, "YYYY-MM-DD");
        const developEndDate = moment(task.developEndDate, "YYYY-MM-DD");

        if (releaseDate.isAfter(developEndDate)) {
            task.releaseDate = inputReleaseDate;
            this.saveTask(task);
        }
        else {
            const dialog = this.$refs.confirmDialog as ConfirmDialog;
            if (!dialog) {
                return;
            }

            const confirmResult = await dialog.showAsync(
                "公開日変更確認",
                "公開日は開発終了日より後でなければいけません。\n選択された公開日：" + inputReleaseDate.split("-").join("/") + "\n開発終了日：" + task.developEndDate.split("-").join("/") + "\nどうしますか？",
                "わかりました。再度選択します",
                "公開日の変更をキャンセルします"
            );

            if (!confirmResult) {
                return;
            }

            // ダイアログを再度表示
            this.openReleaseDateEdit(event, task);
        }
    }

    private async openDatePickerDialog(event: MouseEvent, content: string): Promise<string> {
        const dialog = this.$refs.datePickerDialog as TableDatePickerDialog;
        if (!dialog) {
            return content;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return content;
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(content, rect.left, rect.top);
        if (!result) {
            throw new Error("input cancel");
        }
        return result;
    }

    private async openTextFieldDialog(event: MouseEvent, content: string): Promise<string> {
        const dialog = this.$refs.textFieldDialog as TableTextFieldDialog;
        if (!dialog) {
            return content;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return content;
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(content, rect.left, rect.top);
        if (!result) {
            throw new Error("input cancel");
        }
        return result;
    }

    private async submitSearch(): Promise<void> {
        this.isLoading = true;
        this.searchParams.sheetId = this.sheetStore.currentSheetId;
        await this.taskStore.fetchTasks(this.searchParams);
        this.saveSearchParam();
        this.isLoading = false;
    }

    private resetSearchDialog(): void {
        this.searchParams = CONFIG.DefaultGanttChartSearchParam();
    }

    private async openSelectableItem(event: MouseEvent, items?: {[key: number]: IdAndName;}, values?: {id: number, name: string}[]): Promise<number | undefined> {
        const dialog = this.$refs.selectableItem as TableSelectableItem;
        if (!dialog) {
            return undefined;
        }

        const target = event.target as HTMLElement;
        if (!target) {
            return undefined;
        }

        if (!items) {
            items = [{id: 0, name: ""}];
        }

        const rect = target.getBoundingClientRect();
        const result = await dialog.showAsync(items, rect.left, rect.top, values);
        if (!result) {
            throw new Error("input cancel");
        }
        return result;
    }

    private systemColClass(): string {
        return "system-col";
    }

    private selectTask(taskId: number): void {
        if (this.taskStore.selectedTaskIdList.indexOf(taskId) >= 0) {
            this.taskStore.unselectTask(taskId);
        }
        else {
            this.taskStore.selectTask(taskId);
        }
    }

    private async showTaskThread(task: TaskModel): Promise<void> {
        const taskThreadView = this.$refs.taskThreadView as TaskThreadView;
        if (taskThreadView && task) {
            // 選択状態にする
            this.selectTask(task.id);

            if (this.openedThreadId === task.id) {
                this.openedThreadId = 0;
                taskThreadView.close();
            }
            else {
                this.openedThreadId = task.id;
                taskThreadView.showAsync(task);
            }
        }
    }

    /**
     * 開発期限を過ぎているかどうかを判定する
     *
     * @param {string} developEndDate 開発終了日
     */
    private isOverDevelopLimitDay(task: TaskModel): boolean {
        if (task.statusId === 99) {
            return false;
        }

        const developEndDate = moment(task.developEndDate, "YYYY-MM-DD");
        const today = moment();

        return developEndDate.isBefore(today);
    }
}

import { render, staticRenderFns } from "./GanttChartPage.vue?vue&type=template&id=5f6f2ca0&scoped=true&"
import script from "./GanttChartPage.vue?vue&type=script&lang=ts&"
export * from "./GanttChartPage.vue?vue&type=script&lang=ts&"
import style0 from "./GanttChartPage.vue?vue&type=style&index=0&lang=css&"
import style1 from "./GanttChartPage.vue?vue&type=style&index=1&id=5f6f2ca0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f6f2ca0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBtn,VDataTable,VIcon,VSpacer,VToolbar,VToolbarItems,VToolbarTitle,VTooltip})

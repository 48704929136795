




























































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {GanttChart} from "../../models/interfaces/GanttChart";
import WorkLoadCalclator from "../../models/WorkLoadCalculator";

const displayDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

@Component
export default class GanttChartView extends Vue {
    // #region props
    @Prop({default: []})
    public readonly tasks!: GanttChart[];

    @Prop({default: 40})
    public readonly itemHeight!: number;

    @Prop({default: 10})
    public readonly fontSize!: number;
    // #endregion

    // #region private fields
    private minMonth?: Date;
    private maxMonth?: Date;
    private today = new Date();
    private isScrollSelf = false;
    // カレンダー表示用
    private days: [Date, number][] = [];
    // 横スクロール用
    private dragging = false;
    private workLoadCalclator = new WorkLoadCalclator();
    // #endregion

    /**
     * @summary 縦スクロールをEventのターゲットに同期します.
     */
    public scrollTaskY(element: Element): void {
        const taskBoxWrapper = this.$refs.taskBoxWrapper as Element;
        if (taskBoxWrapper) {
            taskBoxWrapper.scrollTop = element.scrollTop;
        }
        this.isScrollSelf = true;
    }
    // #endregion

    private mounted() {
        this.initScrollX();
        this.init();
    }

    /**
     * @summary カレンダーの初期化を行います.
     */
    @Watch("tasks")
    private init() {
        if (this.tasks.length === 0) {
            return;
        }
        let maxDate = this.tasks[0].startDate ? this.tasks[0].startDate : new Date();
        for (const item of this.tasks) {
            if (item.completeDate && maxDate < item.completeDate) {
                maxDate = item.completeDate;
            }
        }

        let minDate = this.tasks[0].startDate ? this.tasks[0].startDate : new Date();
        for (const item of this.tasks) {
            if (item.startDate && item.startDate < minDate) {
                minDate = item.startDate;
            }
        }

        this.minMonth = new Date(minDate.getFullYear(), minDate.getMonth());
        this.maxMonth = new Date(maxDate.getFullYear(), maxDate.getMonth());

        this.days = [];
        const currentDate = new Date(minDate);
        const maxMonth = this.workLoadCalclator.getMonthDiff(minDate, maxDate) + 1;
        for (let i = 0; i < maxMonth; i++) {
            this.days.push([
                new Date(currentDate),
                this.workLoadCalclator.getDayCountOfMonth(currentDate)
            ]);
            // 1月進める
            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        this.$nextTick(() => this.syncHeaderAndContentSize());
    }

    /**
     * @summary 横スクロールイベントに関する初期化処理.
     */
    private initScrollX(): void {
        let movement = 0;
        window.addEventListener("mousemove", (e) => {
            if (this.dragging) {
                const taskBoxWrapper = this.$refs.taskBoxWrapper as HTMLElement;
                if (taskBoxWrapper) {
                    movement = e.movementX;
                    taskBoxWrapper.style.scrollBehavior = "";
                    taskBoxWrapper.scrollLeft = taskBoxWrapper.scrollLeft - movement;
                }
            }
        });

        window.addEventListener("mouseup", (e) => {
            if (this.dragging) {
                const taskBoxWrapper = this.$refs.taskBoxWrapper as HTMLElement;
                if (taskBoxWrapper) {
                    const scroll = taskBoxWrapper.scrollLeft - (movement * 20);
                    taskBoxWrapper.style.scrollBehavior = "smooth";
                    taskBoxWrapper.scrollLeft = scroll;
                }
                this.dragging = false;
            }
        });
    }

    /**
     * @summary UIのタスク部分とヘッダー部分のサイズを同期します.
     */
    private syncHeaderAndContentSize(): void {
        const headerElement = this.$refs.header as HTMLElement;
        const taskBox = this.$refs.taskBox as HTMLElement;
        if (headerElement && taskBox) {
            taskBox.style.width = (headerElement.scrollWidth - 12) + "px";
        }
    }

    /**
     * @summary UIのタスク部分がスクロールされたとき処理.
     * @description UIのヘッダー部分をスクロールさせタスク部分の位置と同期します.
     */
    private onScrollTaskHeaderX(e: Event): void {
        const headerElement = this.$refs.header as HTMLElement;
        if (headerElement) {
            headerElement.scrollLeft = (e.srcElement as HTMLElement).scrollLeft;
        }
        if (this.isScrollSelf) {
            this.isScrollSelf = false;
            return;
        }
        this.$emit("scroll", e);
    }

    /**
     * シートの最小月の隣に新しい月を追加します.
     */
    private createPreviousMonth(): void {
        this.minMonth!.setMonth(this.minMonth!.getMonth() - 1);
        this.days.unshift([
            new Date(this.minMonth!),
            this.workLoadCalclator.getDayCountOfMonth(this.minMonth!)
        ]);

        const taskBoxWrapper = this.$refs.taskBoxWrapper as HTMLElement;
        if (taskBoxWrapper) {
            const scrollWidth = taskBoxWrapper.scrollWidth;
            this.$nextTick(() => {
                this.syncHeaderAndContentSize();
                const offset = taskBoxWrapper.scrollWidth - scrollWidth;
                // オフセットの位置までずらしてスムーズにスクロール
                taskBoxWrapper.style.scrollBehavior = "";
                taskBoxWrapper.scrollLeft += offset;
                taskBoxWrapper.style.scrollBehavior = "smooth";
                taskBoxWrapper.scrollLeft = 0;
            });
        }
    }

    /**
     * シートの最大月の隣に新しい月を作成します.
     */
    private createNextMonth(): void {
        this.maxMonth!.setMonth(this.maxMonth!.getMonth() + 1);
        this.days.push([
            new Date(this.maxMonth!),
            this.workLoadCalclator.getDayCountOfMonth(this.maxMonth!)
        ]);

        this.$nextTick(() => {
            this.syncHeaderAndContentSize();
            const taskBoxWrapper = this.$refs.taskBoxWrapper as HTMLElement;
            if (taskBoxWrapper) {
                taskBoxWrapper.style.scrollBehavior = "smooth";
                taskBoxWrapper.scrollLeft = taskBoxWrapper.scrollWidth;
            }
        });
    }

    /**
     * メンバフィールドの一番小さい月の1日から指定した日付までが何日あるか算出します.
     * @param 日付
     * @returns 何日あるか
     */
    private getDateDiffFromMinMonth(date: Date): number {
        if (!this.minMonth || !date) {
            return 0;
        }
        return this.workLoadCalclator.getDateDiff(this.minMonth!, date);
    }

    /**
     * 指定した日付に応じたスタイルを取得します.
     * @param 月
     * @param 日
     * @returns 色のCSS
     */
    private getStyleFromDay(month: Date, date: number): string {
        const _month = new Date(month);
        _month.setDate(date);
        const day = _month.getDay();

        // 今日
        if (_month.getFullYear() === this.today.getFullYear() &&
            _month.getMonth() === this.today.getMonth() &&
            _month.getDate() === this.today.getDate()) {
            return "background: rgba(245, 242, 172, 0.47);";
        }

        if (day === 6) {
            return "background:rgba(193, 193, 193, 0.18);";
        }
        else if (day === 0) {
            return "background:rgba(193, 193, 193, 0.18);";
        }

        // 祝日だったら
        if (this.workLoadCalclator.isHoliday(_month.toLocaleDateString())) {
            return "background:rgba(193, 193, 193, 0.18);";
        }
        return "";
    }

    /**
     *
     * @param date 指定した日付
     */
    private isToday(date: Date): boolean {
        return date.getMonth() === this.today.getMonth() &&
            date.getDate() === this.today.getDate() &&
            date.getFullYear() === this.today.getFullYear();
    }

    /**
     * 日付の表示名を取得します.
     * @param month 月
     * @param date 日
     * @returns 表示名
     */
    private getDisplayDayName(month: Date, date: number): string {
        const _month = new Date(month);
        _month.setDate(date);
        _month.getDay();
        return displayDays[_month.getDay()];
    }
}
